import React, { useEffect, useRef, useState } from "react";  
import Autocomplete from "react-google-autocomplete";  
import { FaLock } from "react-icons/fa6";  
import { IoLocationOutline } from "react-icons/io5";  
import { Link } from "react-router-dom";  

const center = {  
  lat: 0,  
  lng: 0,  
};  

const formatPhoneNumber = (value) => {  
  if (!value) return value;  
  const phoneNumber = value.replace(/[^\d]/g, '');  
  const phoneNumberLength = phoneNumber.length;  
  if (phoneNumberLength < 4) return phoneNumber;  
  if (phoneNumberLength < 7) {  
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;  
  }  
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;  
};  

const Slide1 = ({ onNext }) => {  
  const [selectedLocation, setSelectedLocation] = useState(center);  
  const [inputValue, setInputValue] = useState("");  
  const [inputZipCodeValue, setInputZipCodeValue] = useState("");  
  const [isValidAddress, setIsValidAddress] = useState(true);  
  const [errors, setErrors] = useState({});  
  const [isFormValid, setIsFormValid] = useState(false);  
  const [formData, setFormData] = useState({ personName: "", personPhone: "" });  
  const [serviceAreaMessage, setServiceAreaMessage] = useState("We'll confirm you're in our service area");  

  const mapRef = useRef(null);  
  const inputRef = useRef(null);  
  const nameInputRef = useRef(null);  
  const phoneInputRef = useRef(null);  

  useEffect(() => {  
    const { personName, personPhone, locationData } = formData;  
    const isNameValid = !personName || /^[a-zA-Z\s'-]{2,}$/.test(personName);  
    const isPhoneValid = !personPhone || /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(personPhone);  
    const islocationDataValid = locationData;  

    setIsFormValid(personName && personPhone && isNameValid && isPhoneValid && islocationDataValid);  

    setErrors({  
      personName: personName && !isNameValid ? "Invalid name format" : "",  
      locationData: !islocationDataValid ? "Invalid name format" : "",  
      personPhone: personPhone && !isPhoneValid ? "Invalid phone format" : "",  
    });  
  }, [formData]);  

  useEffect(() => {  
    if (!window.google) {  
      return;  
    }  

    const mapElement = document.getElementById("map");  
    if (!mapElement) {  
      console.error("Map element with id 'map' not found.");  
      return;  
    }  
    const map = new window.google.maps.Map(mapElement, {  
      center: selectedLocation,  
      zoom: 15,  
      mapTypeId: "roadmap",  
      fullscreenControl: false,  
      gestureHandling: "none",  
      zoomControl: false,  
      keyboardShortcuts: false,  
      styles: [  
        {  
          featureType: "all",  
          elementType: "all",  
          stylers: [{ saturation: -100 }, { visibility: "simplified" }],  
        },  
        {  
          featureType: "poi",  
          elementType: "labels",  
          stylers: [{ visibility: "off" }],  
        },  
      ],  
    });  

    const marker = new window.google.maps.Marker({  
      position: selectedLocation,  
      map: map,  
      icon: {  
        url: "/images/pin_icon.svg",  
      },  
    });  

    map.addListener("click", (e) => {  
      marker.setPosition(e.latLng);  
      setSelectedLocation({  
        lat: e.latLng.lat(),  
        lng: e.latLng.lng(),  
      });  
      setIsValidAddress(true);  
    });  
  }, [selectedLocation]);  

  useEffect(() => {  
    if (inputRef.current) {  
      inputRef.current.focus();  
    }  
  }, []);  

  // Update service area message based on inputValue  
  useEffect(() => {  
    if (inputValue.includes("San Diego")) {  
      setServiceAreaMessage("Great, you're in our service area. Let's get you a quote.");  
    } else {  
      setServiceAreaMessage("We'll confirm you're in our service area");  
    }  
  }, [inputValue]);  

  const handleButtonClick = (e) => {  
    const { personName, personPhone } = formData;  

    const isNameValid = personName && /^[a-zA-Z\s'-]{2,}$/.test(personName);  
    const isPhoneValid = personPhone && /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(personPhone);  

    if (!isNameValid) {  
      e.preventDefault();  
      setErrors((prevErrors) => ({  
        ...prevErrors,  
        personName: "Invalid name format",  
      }));  
    }  

    if (!isPhoneValid) {  
      e.preventDefault();  
      setErrors((prevErrors) => ({  
        ...prevErrors,  
        personPhone: "Invalid phone format",  
      }));  
    }  
  };  

  const handlePlaceSelected = (place) => {  
    if (place?.geometry && place?.geometry?.location) {  
      setInputValue(place?.formatted_address);  
      setInputZipCodeValue(place.address_components.find(x => x.types.find(y => y === 'postal_code'))?.long_name);  
      setSelectedLocation({  
        lat: place.geometry.location.lat(),  
        lng: place.geometry.location.lng(),  
      });  
      setIsValidAddress(true);  
    } else {  
      setInputValue("");  
      setInputZipCodeValue("");  
      setIsValidAddress(false);  
    }  
  };  

  const handleInputChange = (e) => {  
    const { name, value } = e.target;  

    let updatedValue = value;  
    if (name === "personPhone") {  
      updatedValue = formatPhoneNumber(value);  
    }  
    if (name && value) {  
      if (name === "locationData") {  
        setInputValue(updatedValue);  
        setSelectedLocation(center);  
        setIsValidAddress(false);  
      }  
    }  
    setFormData({  
      ...formData,  
      [name]: updatedValue,  
    });  
  };  

  const isValidLocation =  
    selectedLocation.lat !== 0 && selectedLocation.lng !== 0;  

  return (  
    <div className="form-container">  
      <form  
        onSubmit={(e) => {  
          e.preventDefault();  
          console.log(formData);  
          if (isFormValid) {  
            var firstName = formData.personName.split(' ').slice(0, -1).join(' ');  
            var lastName = formData.personName.split(' ').slice(-1).join(' ');  
            const selectedAddress = {  
              address: inputValue,  
              location: selectedLocation,  
              firstName: firstName,  
              lastName: lastName,  
              phone: formData.personPhone,  
              zipCode: inputZipCodeValue  
            };  
            window.scrollTo(0, 0);  
            onNext(true, selectedAddress);  
          }  
        }}  
      >  
        <div className="step-section">  
          <h1>Let’s start with your address</h1>  
          <p className="service area">{serviceAreaMessage}</p>  
        </div>  
        {isValidAddress && inputValue.length > 10 && isValidLocation && (  
          <div className="row">  
            <div className="map-container-parent col-md-12">  
              <div  
                className="map_container"  
                id="map"  
                ref={mapRef}  
                style={{ height: "150px", width: "100%" }}  
              ></div>  
            </div>  
          </div>  
        )}  

        <div className="field-mini-wrap">  
          <div className="row">  
            <div className="col-md-12">  
              <div className="input-container form-floating mt-5 position-relative">  
                <IoLocationOutline className="icon" />  
                <Autocomplete  
                  ref={inputRef}  
                  className={`form-control input-field street-field  ${  
                    inputValue ? "has-content" : ""  
                  } ${  
                    !isValidAddress && inputValue !== "" && !isValidLocation  
                      ? "i-cant-found"  
                      : ""    
                  }`}  
                  apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}  
                  options={{  
                    types: ["address"],  
                    componentRestrictions: { country: "us" },  
                  }}  
                  onPlaceSelected={handlePlaceSelected}  
                  onChange={handleInputChange}  
                  aria-invalid={!!errors.locationData}  
                  name="locationData"  
                />  
                <label  
                  htmlFor="form-name"  
                  className={`address ${inputValue ? `street-address` : ""}`}  
                >  
                  Street address  
                </label>  
                {!isValidAddress && inputValue !== "" && !isValidLocation && (
                  <div className="address-no-results">
                    <Link to="/step/2">I can't find my address</Link>
                  </div>
                )}
              </div>  
            </div>  
          </div>  
          {isValidAddress && isValidLocation && inputValue && (  
            <div className="row">  
              <div className="col-md-6">  
                <div className="input-container form-floating position-relative input-box">  
                  <input  
                    className={`form-control input-field pac-target-input ${  
                      errors.street ? "invalid-field" : ""  
                    }`}  
                    placeholder="Enter a name"  
                    name="personName"  
                    value={formData.personName}  
                    ref={nameInputRef}  
                    id="personName"  
                    pattern="[a-zA-Z\s'-]{2,}"  
                    aria-invalid={!!errors.personName}  
                    onChange={handleInputChange}  
                  />  
                  <label htmlFor="personName">First and last name</label>  
                </div>  
              </div>  
              <div className="col-md-6">  
                <div className="input-container form-floating position-relative input-box">  
                  <input  
                    className={`form-control input-field pac-target-input ${  
                      errors.phone ? "invalid-field" : ""  
                    }`}  
                    placeholder="Phone (123-123-1234)"  
                    name="personPhone"  
                    id="personPhone"  
                    type="text"  
                    value={formData.personPhone}  
                    ref={phoneInputRef}  
                    onChange={handleInputChange}  
                    aria-invalid={!!errors.personPhone}  
                    inputMode="numeric"  
                    required  
                  />  
                  <label htmlFor="personPhone">Phone number</label>  
                </div>  
              </div>  
            </div>  
          )}  
        </div>  
        {<div className="lock-bg">  
          <FaLock className="lock-color" />  
          <span className="from-lock">Your information is fully secure.</span>  
        </div>}
        <button  
          type="submit"  
          className={`btn ${  
            !isValidAddress && inputValue !== "" && !isValidLocation  
              ? "next-btn"  
              : "setp-next-btn"  
          }  
          ${  
            !isFormValid  
              ? "button-disabled"  
              : ""  
          }  
          `}  
          onClick={handleButtonClick}  
        >  
          Next  
        </button>  
        <input type="hidden" id="progress_slide_1" data-width="0%"/>  
        <div className="under-button-text">By continuing, you are agreeing to receive text messages from PoolLogic.<div></div>Message and data rates may apply, and the frequency of messages may vary.<br />You may opt out at any time.</div>  
      </form>  
    </div>  
  );  
};  

export default Slide1;