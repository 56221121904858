import React, { useEffect, useRef, useState } from "react";
import { FaLock } from "react-icons/fa6";

const formatPhoneNumber = (value) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

const Slide21 = ({ onNext, onBack }) => {
  const [formData, setFormData] = useState({
    personName: "",
    personPhone: "",
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const [errors, setErrors] = useState({});
  const nameInputRef = useRef(null);
  const phoneInputRef = useRef(null);

  useEffect(() => {
    const { personName, personPhone } = formData;
    const isNameValid = !personName || /^[a-zA-Z\s'-]{2,}$/.test(personName);
    const isPhoneValid = !personPhone || /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(personPhone);;

    setIsFormValid(personName && personPhone && isNameValid && isPhoneValid);

    setErrors({
      personName: personName && !isNameValid ? "Invalid name format" : "",
      personPhone: personPhone && !isPhoneValid ? "Invalid phone format" : "",
    });
  }, [formData]);
  const handleButtonClick = (e) => {
    
  
  const { personName, personPhone } = formData;

  const isNameValid = personName && /^[a-zA-Z\s'-]{2,}$/.test(personName);
  const isPhoneValid = personPhone && /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(personPhone);

  if (!isNameValid) {
    e.preventDefault();
    setErrors((prevErrors) => ({
      ...prevErrors,
      personName: "Invalid name format",
    }));
  }

  if (!isPhoneValid) {
    e.preventDefault();
    setErrors((prevErrors) => ({
      ...prevErrors,
      personPhone: "Invalid phone format",
    }));
  }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    if(name === "personPhone"){
      updatedValue = formatPhoneNumber(value);
    }
    setFormData({
      ...formData,
      [name]: updatedValue,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormValid) {
      window.scrollTo(0, 0);
      onNext("manualAddress1", formData);
    } else {
      const firstInvalidField = Object.keys(errors).find((key) => errors[key]);
      if (firstInvalidField) {
        document.getElementById(firstInvalidField).focus();
      }
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <div className="step-section">
          <h1>How can we contact you?</h1>
        </div>
        <div className="field-mini-wrap">
          <div className="row">
            <div className="col-md-6">
              <div className="input-container form-floating position-relative input-box">
                <input
                  className={`form-control input-field pac-target-input ${
                    errors.street ? "invalid-field" : ""
                  }`}
                  placeholder="Enter a name"
                  name="personName"
                  value={formData.personName}
                  ref={nameInputRef}
                  id="personName"
                  pattern="[a-zA-Z\s'-]{2,}"
                  onChange={handleInputChange}
                  aria-invalid={!!errors.personName}
                />
                <label htmlFor="personName">First and last name</label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-container form-floating position-relative input-box">
                <input
                  className={`form-control input-field pac-target-input ${
                    errors.phone ? "invalid-field" : ""
                  }`}
                  placeholder="Phone (123-123-1234)"
                  name="personPhone"
                  id="personPhone"
                  type="text"
                  value={formData.personPhone}
                  ref={phoneInputRef}
                  onChange={handleInputChange}
                  aria-invalid={!!errors.personPhone}
                  inputMode="numeric"
                  required
                />
                <label htmlFor="personPhone">Phone number</label>
              </div>
            </div>
          </div>
        </div>
        <div className="lock-bg">
          <FaLock className="lock-color" />
          <span className="from-lock">Your information is fully secure.</span>
        </div>
        <button type="submit" className={`btn btn-next
          ${
            !isFormValid
              ? "button-disabled"
              : ""
          }
          `}
          onClick={handleButtonClick}>
          Next
        </button>
        <input type="hidden" id="progress_slide_1" data-width="30%"/>
      </form>
    </div>
  );
};

export default Slide21;
