import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Footer = () => {

  return (
    <>
      <div className="google-reviews">
        <img src="/images/google_data.png"></img>
      </div>
    </>
  );
};

export default Footer;
